import lozad from 'lozad';
import Swiper from "swiper";
import {Autoplay, Navigation, Pagination} from 'swiper/modules';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
import './animation';

import CounterAnimation from "@teamthunderfoot/counter-animation";

(function ($) {
    $(document).ready(function () {

        const observer = lozad();
        observer.observe();
        $('#select_lang').on('change', function () {
            let value = this.value
            window.location.href = value
        })

        //header
        $('.navbar_toggler').click(function (event) {
            if ($("header nav").hasClass("active")) {
                $(this).removeClass('active');
                $("header nav").removeClass('active');
                $('.js_nav_open').hide();
                $('body').removeClass('hidden_body');
            } else {
                $(this).addClass('active');
                $("header nav").addClass('active');
                $('.js_nav_open').show();
                $('body').addClass('hidden_body');
            }
        });


        if ($(window).width() <= 1200) {
            $('header nav ul').on('click', 'li', function () {
                if ($('div', this).hasClass("header__nav-submenu")) {

                    if ($('div', this).hasClass("open_drop")) {
                        $('div', this)
                            .hide()
                            .removeClass("open_drop")
                            .addClass("close_drop");
                        $(this).removeClass("active");
                    } else {
                        $('div', this)
                            .show()
                            .addClass('open_drop')
                            .removeClass("close_drop");
                        $(this).addClass("active");
                    }
                }
            });
        } else {
            $("header nav li").hover(
                function () {
                    $('.header__nav-submenu', this).show();
                }, function () {
                    $('.header__nav-submenu', this).hide();
                }
            );
        }


        //tabs
        $('ul.tabs__caption').on('click', 'li:not(.active)', function () {
            $(this)
                .addClass('active').siblings().removeClass('active')
                .closest('.tabs').find('.tabs__content').removeClass('active').eq($(this).index()).addClass('active')
        })

        //accordion
        const accordions = document.querySelectorAll(".accordion");
        const openAccordion = (accordion) => {
            const content = accordion.querySelector(".accordion__content");
            accordion.classList.add("accordion__active");
        };
        const closeAccordion = (accordion) => {
            const content = accordion.querySelector(".accordion__content");
            accordion.classList.remove("accordion__active");
        };
        accordions.forEach((accordion) => {
            const intro = accordion.querySelector(".accordion__intro");
            const content = accordion.querySelector(".accordion__content");
            intro.onclick = () => {
                if (accordion.classList.contains('accordion__active')) {
                    closeAccordion(accordion);
                } else {
                    //accordions.forEach((accordion) => closeAccordion(accordion)); //close prev item
                    openAccordion(accordion);
                }
            };
        });


        //scroll
        $('.anchor[href*="#"]:not([href="#"])').click(function () {
            if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
                let target = $(this.hash);
                let menuHeight = $('header').outerHeight();
                target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
                if (target.length) {
                    $('html, body').animate({
                        scrollTop: target.offset().top - menuHeight
                    }, 1000);
                    return false;
                }
            }
        });


        //sliders
        const casesSwiperSelector = document.querySelector('.cases__slider');
        const casesSwiper = new Swiper(casesSwiperSelector, {
            modules: [Navigation],
            slidesPerView: 1,
            spaceBetween: 12,
            navigation: {
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
            },
            breakpoints: {
                768: {
                    slidesPerView: 2,
                    spaceBetween: 32,
                },
                1200: {
                    slidesPerView: 3,
                    spaceBetween: 32,
                }
            }
        });

        const aboutBusinessSwiperSelector = document.querySelector('.about-business__slider');
        const aboutBusinessSwiper = new Swiper(aboutBusinessSwiperSelector, {
            modules: [Navigation],
            slidesPerView: 1,
            spaceBetween: 12,
            navigation: {
                prevEl: ".swiper-button-prev",
                nextEl: ".swiper-button-next",
            },
            breakpoints: {
                768: {
                    slidesPerView: 2,
                    spaceBetween: 16,
                },
                992: {
                    slidesPerView: 3,
                    spaceBetween: 20,
                },
                1400: {
                    slidesPerView: 4,
                    spaceBetween: 32,
                }
            }
        });

        const projectSwiperSelector = document.querySelector('.project__slider');
        if (projectSwiperSelector != null) {
            const swiper = new Swiper(projectSwiperSelector, {
                modules: [Navigation, Autoplay],
                slidesPerView: 1,
                spaceBetween: 36,
                navigation: {
                    prevEl: ".swiper-button-prev",
                    nextEl: ".swiper-button-next",
                },
                autoplay: {
                    enabled: true,
                    delay: 0,
                    disableOnInteraction: false,
                    pauseOnMouseEnter: true,
                },
                loop: true,
                speed: 5000,
                breakpoints: {
                    320: {
                        slidesPerView: 1,
                        loop: false,
                        speed: 1000,
                        autoplay: {
                            enabled: false,
                        }
                    },
                    768: {
                        slidesPerView: 2,
                        loop: false,
                        speed: 1000,
                        autoplay: {
                            enabled: false,
                        }
                    },
                    992: {
                        slidesPerView: 3,
                        loop: false,
                        speed: 1000,
                        autoplay: {
                            enabled: false,
                        }
                    },
                    1200: {
                        slidesPerView: 4,
                        spaceBetween: 32,
                        effect: false,
                        speed: 5000,
                        autoplay: {
                            pauseOnMouseEnter: true,
                        }
                    },
                    1920: {
                        slidesPerView: 5,
                        spaceBetween: 32,
                        effect: false,
                        speed: 5000,
                        autoplay: {
                            pauseOnMouseEnter: true,
                        }
                    },
                }
            });

            let windowWidth = window.screen.width;
            if (windowWidth > 1200) {
                $(".project__slider").hover(function () {
                    handleTogglePlay();
                }, function () {
                    // projectSwiper.autoplay.start();
                    handleTogglePlay();
                    swiper.autoplay.start();
                });


                const swiperWrapperEl = projectSwiperSelector.querySelector('.swiper-wrapper');
                const setEasing = (easing = 'ease') => {
                    swiperWrapperEl.style.setProperty('--easing', easing);
                };

                let duration, distanceRatio, startTimer;


                const stopAutoplay = () => {
                    if (startTimer) clearTimeout(startTimer);
                    swiper.setTranslate(swiper.getTranslate());

                    // Calculating the distance between current slide and next slide.
                    // 0.3 is equal to 30% distance to the next slide.
                    // distanceRatio = Math.abs((swiper.width * swiper.activeIndex + swiper.getTranslate()) / swiper.width);

                    // currentSlideWidth for slidesPerView > 1
                    const currentSlideWidth = swiper.slides[swiper.activeIndex].offsetWidth;
                    distanceRatio = Math.abs((currentSlideWidth * swiper.activeIndex + swiper.getTranslate()) / currentSlideWidth);

                    // The duration that playing to the next slide
                    duration = swiper.params.speed * distanceRatio;
                    swiper.autoplay.stop();
                };

                const startAutoplay = (delay = duration) => {
                    startTimer = setTimeout(() => {
                        setEasing('linear');
                        swiper.autoplay.start();
                    }, delay);
                };

                // toggle play

                let isPlaying = true;
                let clickable = true;
                const handleTogglePlay = () => {
                    if (!clickable) return;
                    clickable = false;

                    if (isPlaying) stopAutoplay();
                    else {
                        const distance = swiper.width * swiper.activeIndex + swiper.getTranslate();

                        // Avoid distance that is exactly 0
                        duration = distance !== 0 ? duration : 0;
                        swiper.slideTo(swiper.activeIndex, duration);
                        startAutoplay();
                    }
                    isPlaying = !isPlaying;

                    setTimeout(() => {
                        clickable = true;
                    }, 100);
                };

                // prev next
                const handleSwitch = (type, setIndex) => {
                    if (!clickable) return;
                    clickable = false;
                    stopAutoplay();

                    setEasing('ease');
                    const switchSpeed = 2000;

                    const index = setIndex();
                    if (index === 999) swiper.slideNext(switchSpeed);
                    else if (index === -999) swiper.slidePrev(switchSpeed);
                    else swiper.slideTo(index, switchSpeed);

                    if (isPlaying) {
                        let startAutoplayDelay = switchSpeed;
                        if (type === 'prev') {
                            // 500 => Solve the problem that cannot slide prev continuously during autoplay
                            startAutoplayDelay = switchSpeed + 500
                        }
                        startAutoplay(startAutoplayDelay);
                    }
                    setTimeout(() => {
                        clickable = true;
                    }, switchSpeed);
                };
            }


            function swiperMeansDisplay() {

                if ($('.means__slider').length) {
                    let meansSlider = new Swiper(".means__slider", {
                        modules: [Navigation],
                        slidesPerView: 1,
                        spaceBetween: 10,
                        navigation: {
                            prevEl: ".swiper-button-prev",
                            nextEl: ".swiper-button-next",
                        },
                    });

                    let windowWidth = window.screen.width;
                    if (windowWidth > 768) {
                        meansSlider.destroy()
                    }
                }
            }

            //first init
            swiperMeansDisplay()
            //etc

            //alternative resize
            function resizeHandler() {
                swiperMeansDisplay()
                //etc
            }

            window.addEventListener("resize", resizeThrottler, false);
            let resizeTimeout;

            function resizeThrottler() {
                if (!resizeTimeout) {
                    resizeTimeout = setTimeout(function () {
                        resizeTimeout = null;
                        resizeHandler();
                    }, 250);
                }
            }


        }


        document.querySelectorAll(".about-hero__list_item").forEach((element) => {
            let counter = element.querySelector('span');
            new CounterAnimation({
                element: counter,
                regionFormat: "eu",
                separator: '',
                duration: 3,
                scrollStart: "90%",
            })
        })


    });
}(jQuery));